import React, { useState, useEffect, useRef } from 'react';
import './App.css'; 
import { FaPaperPlane } from 'react-icons/fa';

function App() {
  const [conversation, setConversation] = useState([]);
  const [messages, setMessages] = useState([]); // Messages that will be shown to the user
  const [message, setMessage] = useState(''); // Holds the user's input
  const [isTyping, setIsTyping] = useState(false); // Tracks if ChatGPT is typing
  const [isLoading, setIsLoading] = useState(false); // Track if loading screen is shown
  const [password, setPassword] = useState(''); // Holds the password input
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false); // Track if password is correct
  const [language, setLanguage] = useState(''); // Tracks language selection

  // Reference to the chat box container for scrolling
  const chatBoxRef = useRef(null);

  // Scroll to the bottom of the chat box
  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  // Automatically scroll to the bottom when a new message is received or when typing starts
  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  // Function to update the assistant message outside the loop
  const updateAssistantMessage = (newContent) => {
    setMessages(prevMessages => {
      const lastMessageIndex = prevMessages.length - 1;
      const updatedMessages = [...prevMessages];
      updatedMessages[lastMessageIndex] = {
        ...updatedMessages[lastMessageIndex],
        content: newContent,
      };
      return updatedMessages;
    });
  };

  // Send the initial system prompt when the app starts
  useEffect(() => {
    const sendInitialPrompt = async () => {
      setIsTyping(true);
  
      const systemPrompt = {
        role: 'system',
        content: language === 'it'
          ? 'Sei un mistico veggente con profonda intuizione. Offri intuizioni simili ai tarocchi e alla cabala e mantieni risposte non oltre le 150 parole'
          : 'You are a mystical psychic with deep intuition. You offer tarot-like insights and Kabbalah and keep responses maximum at 150 words',
      };
  
      const initialConversation = [systemPrompt];
  
      try {
        const res = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer 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`, // Your actual API key
          },
          body: JSON.stringify({
            model: 'gpt-4',
            messages: initialConversation,
          }),
        });
  
        const assistantMessage = (await res.json()).choices[0].message.content;
  
        // Aggiorna la conversazione ma non mostra i primi due messaggi
        const updatedConversation = [
          ...initialConversation,
          { role: 'assistant', content: assistantMessage },
        ];
        setConversation(updatedConversation);
  
        // Aggiungi i nuovi messaggi ai vecchi, escludendo i primi due
        setMessages(prevMessages => [
          ...prevMessages,
          ...(updatedConversation.length > 2 ? updatedConversation.slice(2) : [])
        ]);
      } catch (error) {
        console.error('Errore durante la richiesta API:', error);
      } finally {
        setIsTyping(false);
        setIsLoading(false);
      }
    };
  
    if (isPasswordCorrect && language) {
      setIsLoading(true);
      sendInitialPrompt();
    }
  }, [isPasswordCorrect, language]);

  const handleSendMessage = async () => {
    if (message.trim() === '') return;

    // Update the conversation context
    const updatedConversation = [
      ...conversation,
      { role: 'user', content: message },
    ];
    setConversation(updatedConversation);

    // Update messages for UI (this is what the user sees)
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'user', content: message }
    ]);

    setMessage('');
    setIsTyping(true);

    // Start fetching the response with streaming
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 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`, // Your actual API key
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: updatedConversation,
          stream: true,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let assistantMessage = '';

      // Placeholder for the assistant message
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', content: '' }
      ]);

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n').filter(line => line.trim() !== '');

        for (const line of lines) {
          if (line.startsWith('data:')) {
            const json = JSON.parse(line.replace('data: ', ''));

            if (json.choices[0].delta.content) {
              assistantMessage += json.choices[0].delta.content;

              // Aggiorna il messaggio dell'assistente fuori dal ciclo
              updateAssistantMessage(assistantMessage);
            }
          }
        }
      }

      const newConversation = [
        ...updatedConversation,
        { role: 'assistant', content: assistantMessage },
      ];
      setConversation(newConversation);
    } catch (error) {
      console.error('Errore durante la richiesta API:', error);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handlePasswordSubmit = () => {
    if (password === 'asdf') {
      setIsPasswordCorrect(true); // Password is correct
    } else {
      alert('Password errata! Per favore, riprova.');
    }
  };

  if (!isPasswordCorrect) {
    // Render the password screen
    return (
      <div className="loading-screen">
        <img src="/lunaNera.webp" alt="Loading" style={{ width: '150px', borderRadius: '50px' }} />
        <p>Per favore, inserisci la password per continuare:</p>
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Inserisci la password" 
        />
        <button onClick={handlePasswordSubmit}>Invia</button>
      </div>
    );
  }

  if (!language) {
    // Render language selection screen
    return (
      <div className="loading-screen">
        <img src="/lunaNera.webp" alt="Loading" style={{ width: '150px', borderRadius: '50px' }} />
        <p>Seleziona la tua lingua:</p>
        <select value={language} onChange={(e) => setLanguage(e.target.value)}>
          <option value="" disabled>Seleziona la lingua</option>
          <option value="en">English</option>
          <option value="it">Italiano</option>
        </select>
      </div>
    );
  }

  if (isLoading) {
    // Show loading screen after language is selected
    return (
      <div className="loading-screen">
        <img src="/lunaNera.webp" alt="Loading" style={{ width: '150px', borderRadius: '50px' }} />
        <p>{language === 'it' ? 'Connessione con le forze spirituali...' : 'Connecting with spiritual forces...'}</p>
      </div>
    );
  }

  return (
    <div className="chat-container">
      <div className="chat-box" ref={chatBoxRef}>
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role === 'user' ? 'sent' : 'received'}`}>
            <p>{msg.content}</p>
          </div>
        ))}
        {isTyping && (
          <div className="typing-indicator">
            <p>{language === 'it' ? 'Digitando...' : 'Typing...'}</p>
          </div>
        )}
      </div>
      <div className="input-box">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={language === 'it' ? 'Scrivi un messaggio...' : 'Write a message...'}
        />
        <button onClick={handleSendMessage}>
          <FaPaperPlane />
          {language === 'it' ? 'INVIA' : 'SEND'}
        </button>
      </div>
    </div>
  );
}

export default App;
